@import "../../_settings.scss";

.appbar{
  background: $brown;
  color: #fff;
  padding: $sxs $ssm;
}
.appbar__logo{
  width: 50px;
  height: auto;

  @include media-breakpoint-down(sm) {
    width: 40px;
  }
}
.appbar__apptitle{
  display:flex;
  align-items: center;
}
.appbar__nav-item{

  &:not(:first-child){
    margin-left: $ssm;
  }
  .icon{
    vertical-align: middle;
  }

  > a{
    color: rgba(#fff, 0.8);

    > *{
      color: inherit;
    }
  }

  .avatar{
    height: 46px;
    width: 50px;
    
    @include media-breakpoint-up(md) {
      height: 58px;
      width: 60px;
    }
  }
  svg{
    color: #fff;

    path,
    circle{
      stroke: #fff !important;
    }
  }
}