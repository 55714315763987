@import "../../_settings.scss";

.login__logo{
  width: 80px;
  height: auto;
  display:inline-block;

  @include media-breakpoint-down(sm) {
    width: 50px;
  }
}
.login-page{
  background: $body-bg url(../../images/road-and-houses.svg) right -100px bottom -50px no-repeat;
  background-size: 800px;

  @media screen and (max-height: 850px){
    background-position: top 400px right -100px;
  }
}