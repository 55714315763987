@import "../../settings";

.dropzone,
.form-control.dropzone{
  text-align:center;
  // padding: $ssm;
  // border: 3px dashed rgba($primary, 0.3);
  // background: rgba($primary, 0.15);
  cursor: pointer;
  height: auto;

  .icon{
    opacity: 0.5;
  }

  img{
    max-width:100% !important;
  }

  p{
    margin: 0;
    font-size: 0.8em;
    font-style: italic;
  }

  &:hover{
    background: rgba($primary, 0.2);
  }
}