@import "../../_settings.scss";

.project{
  text-align: center;
}

.project-preview-image{
  background: $primary-light url("../../images/project-avatar.svg") no-repeat;
  background-size: cover;
  background-position: center;
  // padding-bottom: 66.7%;
  padding-bottom: 50%;
  height:0;
}
.project-preview-image-default{
  background-size: 35%;
}
.project-preview-details{
  position: relative;
}
.project-progress{
  position: absolute;
  top: 0;
  left:$sxs;
  transform: translate(0, -60%);
  width: 46px;

  .CircularProgressbar .CircularProgressbar-text{
    font-size: 28px;
  }
}

.project-title{
  margin-top: ($sxs / 2);
  position: relative;
  font-weight: $font-weight-bold;
}