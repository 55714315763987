@import "../../_settings.scss";

// import font
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,500,500i');

html,
body,
#root{
  height: 100%;
}

body{
  background: $body-bg;
  // background: linear-gradient(to bottom right, $bg-color-primary, $bg-color-secondary); 
  // background: linear-gradient(to bottom right, #eeeeee, mix($orange, white, 20%)); 
  // background: linear-gradient(to top, #076585, #fff);
}