@import "../../settings";

$lv-smallform-breakpoint: sm;

.budget-item-row__label,
.budget-item-row__actions-small {
  display: none;
}

.list-items__head-description,
.list-items__head-tradeDescription,
.list-items__head-tradeBudget,
.list-items__head-budget label,
.list-items__head-spent label,
.list-items__head-available label {
  cursor: pointer;
}

@include media-breakpoint-up($lv-smallform-breakpoint) {
  .list-items__head-tradeDescription {
    // display: block;
    width: 75%;
  }
  .list-items__head-tradeBudget {
    width: 25%;
  }
}

// list items table which transforms into an accordian mobile view
@include media-breakpoint-down($lv-smallform-breakpoint) {
  .list-items--transformer {
    display: block;

    .list-items__head,
    .list-items__body {
      display: block;
    }
    .list-items__head-row {
      display: flex;
    }

    // HEADER ROWS
    .list-items__head-description {
      display: block;
      width: 100% !important;
    }
    .list-items__head-tradeDescription {
      display: block;
      width: 66.6% !important;
    }
    .list-items__head-tradeBudget {
      display: block;
      width: 33.3% !important;
    }
    .list-items__head-quantity,
    .list-items__head-budget,
    .list-items__head-spent,
    .list-items__head-available,
    .list-items__head-actions {
      display: none;
    }

    // ITEM ROWS
    .budget-item-row--default {
      display: flex;
      flex-wrap: wrap;
      // border-top: 1px dashed transparent;
      border-bottom: 1px dashed $grey-light;

      &.is-expanded {
        padding-bottom: $ssm;
        padding-top: $ssm;
        // margin-bottom: $sxs;
        border-top-color: $grey-light;
        .budget-item-row__quantity,
        .budget-item-row__budget,
        .budget-item-row__tradeBudget,
        .budget-item-row__spent {
          // width: 29%;
          width: calc(100% / 3 - 2rem); // allows for padding
        }
      }

      label {
        font-size: 0.75em;
        margin-bottom: 0;
      }
    }
    .budget-item-row--add {
      display: flex;
      padding-top: $sxs/2;
    }

    .budget-item-row__label,
    .budget-item-row__description,
    .budget-item-row__tradeDescription,
    .budget-item-row__quantity,
    .budget-item-row__budget,
    .budget-item-row__tradeBudget,
    .budget-item-row__spent,
    .budget-item-row__available,
    .budget-item-row__actions {
      display: block;
    }

    .budget-item-row__description {
      width: 100%;
      display: flex;

      .form-control {
        font-weight: $font-weight-bold;
      }
    }
    .budget-item-row__tradeDescription {
      width: 66%;
      display: flex;

      .form-control {
        font-weight: $font-weight-bold;
      }
    }
    .budget-item-row__description-field {
      flex-grow: 1;
    }
    .budget-item-row__quantity,
    .budget-item-row__budget,
    .budget-item-row__tradeBudget,
    .budget-item-row__spent,
    .budget-item-row__available,
    .budget-item-row__actions {
      .form-control,
      .match-input {
        padding: 0 0.3rem;
        height: auto;
      }
    }
    .budget-item-row__quantity,
    .budget-item-row__budget,
    .budget-item-row__tradeBudget,
    .budget-item-row__spent {
      width: 33.33%;
    }
    .budget-item-row__available {
      display: none;
    }
    .budget-item-row__actions {
      width: 100%;
    }

    // min-width in small view
    .budget-item-row__description {
      .pl-2 {
        min-width: 3.2rem;
        text-align: right;
      }
    }

    // suggestions
    .budget-item-row--suggested {
      display: flex;
    }
    .budget-item-row__suggested-name {
      flex-grow: 1;
    }
  }
} // .list-items--transformer

.budget-item-bit__mob-show-for-expanded {
  @include media-breakpoint-down($lv-smallform-breakpoint) {
    display: none !important;

    .budget-item-row.is-expanded & {
      display: block !important;
    }
  }
}

.budget-item-bit__mob-show-for-not-expanded {
  display: none !important;

  @include media-breakpoint-down($lv-smallform-breakpoint) {
    display: block !important;

    .budget-item-row.is-expanded & {
      display: none !important;
    }
  }
}
.budget-item-bit__mob-expanded-only {
  display: none !important;

  @include media-breakpoint-down($lv-smallform-breakpoint) {
    .budget-item-row.is-expanded & {
      display: block !important;
    }
  }
}

.budget-item-bit__mob-expanded-only-inlineblock {
  display: none !important;

  @include media-breakpoint-down($lv-smallform-breakpoint) {
    .budget-item-row.is-expanded & {
      display: inline-block !important;
    }
  }
}

// align suggested text
.budget-item-bit__mob-expanded-only-inlineblock.suggested-text {
  float: right;
  margin-right: 6rem;
}
.dropdown-menu--overscroll .suggested-text {
  span {
    font-weight: bold;
  }
}
