@import "../../_settings.scss";

.form-control{
  box-shadow: $input-box-shadow;
  &::placeholder {
    font-size: .835rem;
    line-height: 1.5rem;
    color: $grey-light !important;
  }
}

