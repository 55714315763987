@import "../../_settings.scss";

.btn.project-list-add{
  font-size: 24px;
  width: 10em;
  height: 8em;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
    width: 10em;
    height: 8em;
  }
}