@import "../../_settings.scss";

.btn.property-list-add-property{
  font-size: 24px;
  width: 13em;
  height: 10em;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
    width: 10em;
    height: 8em;
  }
}
.property-preview-image{
  background: $primary-light url("../../images/property-avatar.svg") no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 66.7%;
  height:0;
}
.property-preview-image-default{
  background-size: 35%;
}

.property-preview-details{
  display:flex;
  position:relative;
}
.btn.property-preview-button{
  padding: ($ssm * 1.2) $ssm $ssm;
  display:flex;
  justify-content: center;
  flex-direction: column;
}
.btn.property-preview-button-name{
  flex-grow: 1; 
  text-align: left;
}
.btn.property-preview-button-gallery{
  font-size: 0.6rem;
  text-transform: uppercase;
  line-height: 0.8em;
  text-align: center;

  .icon{
    line-height: 1;
    top:0;
  }
}
.property-title{
  position: relative;
}
.property-progress{
  position: absolute;
  top: 0;
  left:$sxs;
  transform: translate(0, -60%);
  width: 50px;

  .CircularProgressbar .CircularProgressbar-text{
    font-size: 28px;
  }
}