@import "../../_settings.scss";

ul.plans-list {
  list-style-type: none;
}
.plans-list li {
}
.plan-select-hero {
  background: $primary-light;
  background-size: cover;
  background-position: center;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  // padding-bottom: 66.7%;
  padding-bottom: 30%;
  height: 0;
}
.plan-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: stretch;
  position: relative;
  z-index: 9999;
}
.plan-price {
  // flex-basis: 80%;
  position: absolute;
  top: -3rem;
  background: #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  color: $primary;
}
.plan-price-subtitle {
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #aaa;
}
.plan-price-free {
  color: $primary;
  font-weight: bold;
  // border-radius: 50%;
  // width: 3rem;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.plan-current {
  flex-basis: 160%;
}
.plan-select {
  flex-basis: 20%;
  text-align: right;
}

/** STRIPE STYLING... */

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  // background-color: white;
  background-color: #f3f3f3;
  position: relative;
  z-index: 9999;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
