@import "../../_settings.scss";

.btn{// gotta trump bootstrap defaults by adding a layer of specificity here...

  &.btn{ // again, this is specificity hack for default button overiding
    line-height: 1.2em;
    white-space: normal;
  }

  &.btn-none{
    background: transparent;

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active{
      background: transparent;
    }
  }
  &.btn-as-link{
    background: transparent;
    color: $link-color;

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active{
      background: transparent;
      color: $link-hover-color;
    }
  }
  &.btn-swatch{
    position: relative;
    z-index: 0;
    background: none;
    border: none;
    width: 6em;
    height: 5em;
    border: none;
    filter: drop-shadow( 0px 3px 3px rgba(0,0,0,0.3) );
    color: #fff;
    transform: translate3d(0, 0, 0); // see if forcing hardware acceleration helps prevent ios rendering bug

    svg.btn__swatch{
      position:absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate3d(-50%, -50%, 0); // see if forcing hardware acceleration helps prevent ios rendering bug
      z-index: -1;
    }

    &:disabled{
      opacity:0.4;
    }
  }
  &.btn-swatch-primary{

    svg.btn__swatch{
      path{
        fill: $link-color;
      }
    }

    &:disabled,
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active{
      background: none;
      
      svg.btn__swatch{
        path{
          fill: $link-hover-color;
        }
      }
    }
  }

  &.btn-swatch-secondary{
    svg.btn__swatch{
      path{
        fill: $secondary-link-color;
      }
    }
    
    &:disabled,
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active{
      background: none;
      color: #fff;

      svg.btn__swatch{
        path{
          fill: $secondary-link-color-hover;
        }
      }
    }
  }

  &.btn-swatch-lg{
    width: 9em;
    height: 7em;
  }

  &.btn-swatch-xl{
    width: 13em;
    height: 10em;
  }

  &.btn-icon{
    font-size: 0.75rem;
    text-transform: uppercase;

    .icon{
      top:0;
      line-height: 1em;
    }
  }

  &.btn-match-input{
    padding: 0.3rem;
    line-height: 1.5;
  }
}

.btn-swatch__icon{
  width: 1em;
}
.btn-swatch__icon--larger{
  font-size: 1.5em;
}