

.avatar{
  width: 100%;
  height: auto;
}

.avatar-sm{
  height: 50px;
  width: 50px;
}
.avatar-lg{
  height: 150px;
  width: 150px;
}